import { H1 } from "shared-web-react/dist/widgets/text";
import { SmsAuthForm } from "shared-web-react/dist/sms-login-screen";
export function AdminLogin(): React.JSX.Element {
  return (
    <div className="flex w-full  flex-col h-full justify-center items-center bg-[#2A2C38]">
      <H1>Welcome to Candid Admin</H1>
      <SmsAuthForm isForAdmin disableMixpanel redirectToOnLogin="/" />
    </div>
  );
}
